



















































































































































































































import Vue from 'vue'
import getHomePage from '~/apollo/queries/getHomePage.gql'
import getHomeBlocks from '~/apollo/queries/getHomeBlocks.gql'

export default Vue.extend({
  async fetch () {
    const getHomePageQuery = await this.$apolloProvider.defaultClient.query({
      query: getHomePage
    })
    this.pageData = getHomePageQuery.data.page[0]

    const getHomeBlocksQuery = await this.$apolloProvider.defaultClient.query({
      query: getHomeBlocks
    })
    this.homeBlocks = getHomeBlocksQuery.data.homeBlocks
  },
  data () {
    return {
      pageData: null as any,
      homeBlocks: null,
      isMobile: false
    }
  },
  mounted () {
    this.isMobile = window.innerWidth < 600
    window.addEventListener('resize', this.windowResize)
    this.createAccessiBeEmbed()
  },
  destroyed () {
    window.removeEventListener('resize', this.windowResize)
  },
  methods: {
    windowResize () {
      this.isMobile = window.innerWidth < 600
    },
    createAccessiBeEmbed () {
      return false
    }
  },
  head (): any {
    return {
      title: this.pageData ? this.pageData.metaTitle : '',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.pageData ? this.pageData.metaDescription : ''
        }
      ]
    }
  }
})
